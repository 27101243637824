import {Injectable} from '@angular/core';
import {RestService} from './rest.service';
import {DataService} from './data.service';
import {Wallet, WalletRecharge} from '../models/model';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  public static API_ENDPOINT = RestService.API_ENDPOINT + '/wallets';

  constructor(private rest: RestService,
              private _dataService: DataService) {
  }

  public findByAccountId(accountId: string): Promise<Wallet> {
    return this.rest.get(WalletService.API_ENDPOINT + '/account/' + accountId)
      .toPromise();
  }

  public findById(id: string): Promise<Wallet> {
    return this.rest.get(WalletService.API_ENDPOINT + '/' + id)
      .toPromise();
  }

  // Wallet Transaction

  public createTransaction() {}

  // Wallet Recharge

  public createRecharge(walletId: string, recharge: WalletRecharge): Promise<WalletRecharge> {
    return this.rest.post(WalletService.API_ENDPOINT + '/' + walletId + '/recharge', recharge)
      .toPromise();
  }
}

import {Injectable} from '@angular/core';
import {RestService} from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  public static API_ENDPOINT = RestService.API_ENDPOINT + '/messages';

  constructor(private rest: RestService) {
  }
}

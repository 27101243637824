import {Injectable} from '@angular/core';
import {
  Account,
  EMPTY_DATE,
  SessionToken,
  SYSTEM_PHONE_NUMBER,
  WalletRecharge
} from '../models/model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public static runningOnDevEnvironment(): boolean {
    return window.location.host.includes('localhost:');
  }

  public matchSystemPhoneNumber(phone: number): boolean {
    return phone === SYSTEM_PHONE_NUMBER;
  }

  public defaultAccount(): Account {
    return {
      id: undefined,
      dateCreate: new Date().toISOString(),
      secretKey: undefined,
      company: undefined,
      firstname: '',
      lastname: '',
      customSenderName: undefined,
      phoneNumber: undefined,
      authenticationPin: undefined,
      showCustomSenderName: false,
      showWholeName: false,
      showPhoneNumber: false,
      phoneNumberConfirmationDate: undefined,
      lastLoginDate: undefined,
      lastPinUpdateDate: undefined,
      dateUpdateCompany: EMPTY_DATE,
      dateUpdateSenderName: EMPTY_DATE,
      dateUpdateUsername: EMPTY_DATE
    };
  }

  public defaultSessionToken(): SessionToken {
    return {
      id: undefined,
      dateCreate: new Date().toISOString(),
      dateExpire: undefined,
      accountId: undefined,
      value: undefined,
      triggerDescription: 'Dashboard Token',
      location: undefined
    }
  }

  public defaultWalletRecharge(): WalletRecharge {
    return {
      id: undefined,
      dateCreate: new Date().toISOString(),
      dateComplete: EMPTY_DATE,
      walletId: null,
      paymentNumber: null,
      paymentType: null,
      token: null,
      amount: 0
    };
  }
}

export interface Account {
  id: string;
  dateCreate: string;
  secretKey: string;
  company: string;
  firstname: string;
  lastname: string;
  customSenderName: string;
  phoneNumber: string;
  authenticationPin: number;
  showCustomSenderName: boolean;
  showWholeName: boolean;
  showPhoneNumber: boolean;
  phoneNumberConfirmationDate: string;
  lastLoginDate: string;
  lastPinUpdateDate: string;
  dateUpdateCompany: string;
  dateUpdateSenderName: string;
  dateUpdateUsername: string;
}

export interface PhoneNumberVerify {
  id: string;
  dateCreated: string;
  accountId: string;
  valid: boolean;
  number: number;
  localFormat?: string;
  internationalFormat?: string;
  countryPrefix?: string;
  countryCode?: string;
  countryName?: string;
  location?: string;
  carrier?: string;
  lineType: string;
}

export interface SessionToken {
  id: string;
  dateCreate: string;
  dateExpire: number;
  accountId: string;
  value: string;
  triggerDescription: string;
  location: string;
}

export interface Sms {
  id: string;
  dateCreate: string;
  token: string;
  customSenderName: string;
  sender: string;
  recipient: string;
  message: string;
  httpStatus: string;
  httpMessage: string;
}

export interface Wallet {
  id: string;
  dateCreate: string;
  dateUpdate?: string;
  accountId: string;
  amount: string;
}

export interface WalletRecharge {
  id: string;
  dateCreate: string;
  dateComplete?: string;
  walletId: string;
  paymentNumber: string;
  paymentType: string;
  token: string;
  amount: number;
}

export interface WalletTransaction {
  id: string;
  dateCreate: string;
  walletId: string;
  token: string;
  description: string;
  amount: number;
}

export const SYSTEM_PHONE_NUMBER = 4915114307206;
export const EMPTY_DATE = '0000-00-00';

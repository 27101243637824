import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { v4 as uuid } from 'uuid';
import {AuthService} from '../../services/auth.service';
import {AccountService} from '../../services/account.service';
import {WalletService} from '../../services/wallet.service';
import {Account, Wallet, WalletRecharge, WalletTransaction} from '../../models/model';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-recharge',
  templateUrl: './recharge.component.html',
  styleUrls: ['./recharge.component.scss']
})
export class RechargeComponent implements OnInit {

  public account: Account;
  public wallet: Wallet;

  public transactions: WalletTransaction[];
  public recharges: WalletRecharge[];

  public provider: string;
  public rechargeNumber: string = uuid().toUpperCase();

  constructor(private _route: ActivatedRoute,
              private _cd: ChangeDetectorRef,
              private _authService: AuthService,
              private _accountService: AccountService,
              private _walletService: WalletService) {
  }

  ngOnInit() {
    this.transactions = [];
    this.recharges = [];

    this._route.queryParams.subscribe(params => {
      this.provider = params.p || undefined;
      if (this.provider !== 'paypal') {
        this.rechargeNumber = uuid()
          .replace('-', '')
          .substr(0, 10)
          .toUpperCase();
      }
    });

    this.init().then(() => {
      this.prepopulateTransaction();
      this._cd.markForCheck();
    });
  }

  private async init() {
    await this._walletService.findByAccountId(this._authService.getTokenAccountId())
      .then(response => {
        this.wallet = response;
      });

    await this._accountService.findById(this._authService.getTokenAccountId())
      .then(account => {
        this.account = account;
      });
  }

  private findTransactions() {}

  private findRecharges() {}

  private prepopulateTransaction() {
    if (this.account
      && this.account.id
      && this.wallet
      && this.wallet.id) {

      this.transactions.push({
        id: null,
        dateCreate: this.account.dateUpdateUsername,
        walletId: this.wallet.id,
        token: null,
        description: 'Sleek SMS API welcome GIFT!',
        amount: 655
      });
    }
  }

  public getPhoneNumberMask(): string {
    return 'xxxx' + this.account.phoneNumber.substring(5);
  }
}

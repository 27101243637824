import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {DataService} from '../../services/data.service';
import {AccountService} from '../../services/account.service';
import {WalletService} from '../../services/wallet.service';
import {Account, Wallet} from '../../models/model';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent implements OnInit {

  public account: Account;
  public wallet: Wallet;

  public isFormValid: boolean;
  public editableAccount: Account;
  public ENABLE_UPDATE_VIEW: boolean;

  constructor(private _cd: ChangeDetectorRef,
              private _router: Router,
              private _route: ActivatedRoute,
              private _authService: AuthService,
              private _dataService: DataService,
              private _accountService: AccountService,
              private _walletService: WalletService) {}

  ngOnInit() {
    this.editableAccount = this._dataService.defaultAccount();

    this._route.queryParams.subscribe(params => {
      this.ENABLE_UPDATE_VIEW = typeof params.edit !== 'undefined';
    });

    this._authService.getDeveloperAccount().subscribe(value => {
      this.account = value;
      this.editableAccount = {
        ...this.editableAccount,
        company: value.company,
        customSenderName: value.customSenderName,
        firstname: value.firstname,
        lastname: value.lastname,
      };
    });

    this._walletService.findByAccountId(this._authService.getTokenAccountId())
      .then(response => {
        this.wallet = response;
      });
  }

  public hasUsername(): boolean {
    return (this.account.firstname != '' && this.account.lastname != '');
  }

  public formatPhoneNumber(): string {
    const phoneNumber = String(this.account.phoneNumber);

    // filter only numbers from the input
    let cleaned = ('' + phoneNumber).replace(/\D/g, '');

    // check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})$/);

    if (match) {
      return '+' + match[1]
        + ' ' + match[2]
        + ' ' + match[3]
        + ' ' + match[4]
        + ' ' + match[5]
        + ' ' + match[6]
    }
    return phoneNumber
  }

  public enableDisplay(key: string) {
    this.account.showCustomSenderName = (key === 'custom');
    this.account.showPhoneNumber = (key === 'phone');
    this.account.showWholeName = (key === 'name');

    this._accountService.enableDisplay(this.account)
      .then(response => {
      console.log(response);
    });
  }

  public onFormChange() {
    this.isFormValid = (
      this.account.company !== this.editableAccount.company
      || this.account.customSenderName !== this.editableAccount.customSenderName
      || this.account.firstname !== this.editableAccount.firstname
      || this.account.lastname !== this.editableAccount.lastname
    );
  }

  public onFormSubmit() {
    this._accountService.update(
      {
        ...this.account,
        company: this.editableAccount.company,
        customSenderName: this.editableAccount.customSenderName,
        firstname: this.editableAccount.firstname,
        lastname: this.editableAccount.lastname,
      }
    ).then(response => {
        this._router.navigate(['me']).then(() => {
          this.editableAccount = undefined;
          this.ENABLE_UPDATE_VIEW = false;
        });
      });
  }
}

import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {LoginComponent} from './components/login/login.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {DataComponent} from './components/data/data.component';
import {RechargeComponent} from './components/recharge/recharge.component';
import {DocuComponent} from './components/documentation/docu.component';

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'h', component: HomeComponent},
  {path: 'home', component: HomeComponent},
  {path: 'login', component: HomeComponent},
  {path: 'contact', component: HomeComponent},
  {path: 'auth', children: [
      {path: 'sign-in', component: LoginComponent},
      {path: 'sign-out', component: LoginComponent},
      {path: 'sign-up', component: LoginComponent},
    ]},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'me', component: DataComponent},
  {path: 'recharge', component: RechargeComponent},
  {path: 'documentation', component: DocuComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

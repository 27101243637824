import {Injectable} from '@angular/core';
import {RestService} from './rest.service';
import {SessionToken} from '../models/model';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  public static API_ENDPOINT = RestService.API_ENDPOINT + '/sessions';

  constructor(private rest: RestService) {
  }

  public findByValue(): Promise<SessionToken> {
    return this.rest.get(TokenService.API_ENDPOINT)
      .toPromise();
  }

  public create(token: SessionToken): Promise<SessionToken> {
    return this.rest.post(TokenService.API_ENDPOINT, token)
      .toPromise();
  }
}

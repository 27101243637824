import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {Account} from '../../models/model';
import {TokenService} from '../../services/token.service';
import {AccountService} from '../../services/account.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class HeaderComponent implements OnInit {

  public navOpen: boolean;
  public account: Account;

  constructor(private _router: Router,
              private _cd: ChangeDetectorRef,
              private _authService: AuthService,
              private _tokenService: TokenService,
              private _accountService: AccountService) {
  }

  ngOnInit() {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.navOpen = false;
        this.getToken();
      }
    });

    this._authService.getDeveloperAccount().subscribe(value => {
      this.account = value;
    });
  }

  private getToken() {
    this._tokenService.findByValue()
      .then(response => {
        if (response && response.id) {
          this.getAccount();
        } else {
          this.redirectToLogin();
        }
      })
      .catch(error => {
        console.error(error);
        this.redirectToLogin();
      });
  }

  private getAccount() {
    this._accountService.findById(this._authService.getTokenAccountId())
      .then(response => {
        this._authService.setDeveloperAccount(response && response.id
          ? response
          : undefined);
      });
  }

  private redirectToLogin() {
    const url = this._router.url.substring(1);
    if (url.startsWith('h?')
      || url.startsWith('home')
      || url.startsWith('auth')
      || url.startsWith('documentation')) {
      return;
    }

    this._router.navigate(['auth/sign-in'])
      .then(() => {
        this._authService.setToken(null);
        this._authService.setTokenAccountId(null);
      });
  }

  public toggleNav() {
    this.navOpen = !this.navOpen;
  }

  public isMenuPointActive(url: string) {
    return this._router.url.includes('/' + url);
  }
}

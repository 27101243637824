import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {DataService} from '../../services/data.service';
import {TokenService} from '../../services/token.service';
import {ValidationService} from '../../services/validation.service';
import {AccountService} from '../../services/account.service';
import {SessionToken} from '../../models/model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public isRegistrationView: boolean;
  public isUserSigningOut: boolean;

  public isFormValid: boolean;
  public phoneNumber: number;
  public company: string;
  public pin: number;

  constructor(private router: Router,
              private _dataService: DataService,
              private _authService: AuthService,
              private validationService: ValidationService,
              private accountService: AccountService,
              private tokenService: TokenService) {
  }

  ngOnInit() {
    this.isRegistrationView = this.router.url.includes('sign-up');
    this.isUserSigningOut = this.router.url.includes('sign-out');

    if (this.isUserSigningOut) {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('tokenAccountId');
    }
  }

  public onFormChange() {
    let status = true;

    // validate phone number
    if (!this.validationService.checkPhoneNumber(String(this.phoneNumber))) {
      status = false;
    }

    // Registration: validate company
    if (this.isRegistrationView && !this.validationService.checkCompany(this.company)) {
      status = false;
    }

    // Log In: validate pin
    if (!this.isRegistrationView && !this.validationService.checkPin(String(this.pin))) {
      status = false;
    }

    this.isFormValid = status;
  }

  public onFormSubmit() {
    this.isFormValid = false;

    if (this.isRegistrationView) {
      this.register();
    } else {
      this.login();
    }
  }

  private login() {
    const phone = this._dataService.matchSystemPhoneNumber(this.phoneNumber)
      ? String(this.phoneNumber)
      : "237" + this.phoneNumber;
    this.accountService.login(phone, this.pin)
      .then(response => {
        if (response.id) {
          this.createToken(response.id);
        } else {
          alert("Login failed.");
        }
      });
  }

  private register() {
    const account = this._dataService.defaultAccount();
    account.company = this.company;
    account.phoneNumber = this._dataService.matchSystemPhoneNumber(this.phoneNumber)
      ? String(this.phoneNumber)
      : "237" + this.phoneNumber;

    this.accountService.create(account)
      .then(() => {
        this.router.navigate(['/auth/sign-in'])
          .then(() => {
            this.isRegistrationView = false;
          });
      })
      .catch(error => {
        console.error(error);
        alert("Registration failed.");
      });
  }

  private createToken(accountId: string) {
    const token = this._dataService.defaultSessionToken();
    token.accountId = accountId;

    this.tokenService.create(token).then(response => {
      this.validateToken(response);
      if (response && response.id) {
        this.router.navigate(['dashboard']).then();
      }
    });
  }

  private validateToken(response: SessionToken) {
    if (response && response.id) {
      this._authService.setToken(response.value);
      this._authService.setTokenAccountId(response.accountId);
    }
  }
}

import {Injectable} from '@angular/core';
import {RestService} from './rest.service';
import {Account} from '../models/model';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  public static API_ENDPOINT = RestService.API_ENDPOINT + '/account';

  constructor(private rest: RestService) {
  }

  public findById(id: string): Promise<Account> {
    return this.rest.get(AccountService.API_ENDPOINT + '/' + id)
      .toPromise();
  }

  public login(phoneNumber: string, pin: number): Promise<Account> {
    return this.rest.get(AccountService.API_ENDPOINT + '/' + phoneNumber + '/' + pin)
      .toPromise();
  }

  public create(account: Account): Promise<string> {
    return this.rest.post(AccountService.API_ENDPOINT, account)
      .toPromise();
  }

  public update(account: Account): Promise<string> {
    return this.rest.put(AccountService.API_ENDPOINT + '/' + account.id, account)
      .toPromise();
  }

  public enableDisplay(account: Account): Promise<boolean> {
    return this.rest.put(
      AccountService.API_ENDPOINT + '/' + account.id + '/display',
      account
    )
      .toPromise();
  }
}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.scrollToView(params.s || null);
    });
  }

  private scrollToView(section: string) {
    const elt = section !== null
      ? document.querySelector('app-' + section)
      : null;
    if (elt) {
      setTimeout(() => {
        elt.scrollIntoView({behavior: 'smooth'});
      }, 1000);
    }
  }
}

import {Component} from '@angular/core';

@Component({
  selector: 'app-docu-messaging',
  templateUrl: './docu-messaging.component.html',
  styleUrls: []
})
export class DocuMessagingComponent {
  public dateIso = new Date().toISOString();
}

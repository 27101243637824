import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  public static API_ENDPOINT = 'https://api.cloud.gcp.prod.sleeksys.com/sleek-sms-api';

  constructor(private http: HttpClient,
              private _authService: AuthService) {
  }

  private getAuthorization(): HttpHeaders {
    let headers = new HttpHeaders();
    const authToken = this._authService.getToken();
    const authTokenAccountId = this._authService.getTokenAccountId();
    headers = headers.set('Authorization', authToken !== null ? authToken : '');
    headers = headers.set('AccountId', authTokenAccountId !== null ? authTokenAccountId : '');
    return headers;
  }

  public get(url: string): Observable<any> {
    return this.http.get<any>(url, {headers: this.getAuthorization()});
  }

  public post(url: string, body: any): Observable<any> {
    return this.http.post(url, body, {headers: this.getAuthorization()});
  }

  public put(url: string, body: any): Observable<any> {
    return this.http.put(url, body, {headers: this.getAuthorization()});
  }

  public delete(url: string): Observable<any> {
    return this.http.delete(url, {headers: this.getAuthorization()});
  }
}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../services/account.service';
import {DataService} from '../../services/data.service';
import {AuthService} from '../../services/auth.service';
import {TokenService} from '../../services/token.service';
import {Account, SessionToken, Wallet} from '../../models/model';
import {WalletService} from "../../services/wallet.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: []
})
export class DashboardComponent implements OnInit {

  public token: SessionToken;
  public account: Account;
  public wallet: Wallet;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private dataService: DataService,
              private _authService: AuthService,
              private accountService: AccountService,
              private tokenService: TokenService,
              private _walletService: WalletService) {
  }

  ngOnInit() {
    this._walletService.findByAccountId(this._authService.getTokenAccountId())
      .then(response => {
        this.wallet = response;
      });
  }

  public getUsername() {
    if (this.account) {
      return (this.account.firstname && this.account.firstname != '')
        ? this.account.firstname
        : this.account.company;
    }
    return 'Developer';
  }
}

import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Account} from '../models/model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private account = new Subject<Account>();

  public getToken(): string | null {
    return sessionStorage.getItem('token');
  }

  public setToken(value: string) {
    sessionStorage.setItem('token', value);
  }

  public getTokenAccountId(): string | null {
    return sessionStorage.getItem('tokenAccountId');
  }

  public setTokenAccountId(value: string) {
    sessionStorage.setItem('tokenAccountId', value);
  }

  public getDeveloperAccount(): Observable<Account> {
    return this.account.asObservable();
  }

  public setDeveloperAccount(account: Account) {
    this.account.next(account || undefined);
  }
}

import {BrowserModule} from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import {ValidationService} from './services/validation.service';
import {DataService} from './services/data.service';
import {AuthService} from './services/auth.service';
import {RestService} from './services/rest.service';
import {AccountService} from './services/account.service';
import {VerifierService} from './services/verifier.service';
import {TokenService} from './services/token.service';
import {SmsService} from './services/sms.service';
import {WalletService} from './services/wallet.service';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {HomeComponent} from './components/home/home.component';
import {ContactComponent} from './components/contact/contact.component';
import {PricingComponent} from './components/pricing/pricing.component';
import {LoginComponent} from './components/login/login.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {DataComponent} from './components/data/data.component';
import {RechargeComponent} from './components/recharge/recharge.component';
import {PopupComponent} from "./common/popup/popup.component";
import {DocuComponent} from './components/documentation/docu.component';
import {DocuMessagingComponent} from './components/documentation/docu-messaging/docu-messaging.component';
import {DocuVerifierComponent} from './components/documentation/docu-verifier/docu-verifier.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ContactComponent,
    PricingComponent,
    LoginComponent,
    DashboardComponent,
    DataComponent,
    RechargeComponent,
    PopupComponent,
    DocuComponent,
    DocuMessagingComponent,
    DocuVerifierComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'en'},
    ValidationService,
    DataService,
    AuthService,
    RestService,
    AccountService,
    VerifierService,
    TokenService,
    SmsService,
    WalletService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

import {Injectable} from '@angular/core';
import {DataService} from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(private _dataService: DataService) {
  }

  public checkPhoneNumber(phone: string): boolean {
    if (this._dataService.matchSystemPhoneNumber(+phone)) {
      return true;
    }

    if (phone === 'undefined' || phone === '') {
      return false;
    }
    if (phone.startsWith('6')) {
      return (phone.length >= 9 && Number(phone).toString() === phone);
    }
    return DataService.runningOnDevEnvironment();
  }

  public checkCompany(company: string): boolean {
    return company && company.length >= 5;
  }

  public checkPin(pin: string): boolean {
    return pin && pin.length == 6 && this.isAlphaNumeric(pin);
  }

  public isAlphaNumeric(str): boolean {
    let code, i, len;

    for (i = 0, len = str.length; i < len; i++) {
      code = str.charCodeAt(i);
      if (!(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123)) { // lower alpha (a-z)
        return false;
      }
    }
    return true;
  }
}

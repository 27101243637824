import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-docu',
  templateUrl: './docu.component.html',
  styleUrls: ['./docu.component.scss']
})
export class DocuComponent implements OnInit {

  public enableSectionMessaging: boolean;
  public enableSectionPhoneNumberVerifier: boolean;

  constructor(private _route: ActivatedRoute) {
  }

  ngOnInit() {
    this._route.queryParams.subscribe(params => {
      this.enableSectionMessaging = params.s === 'messaging';
      this.enableSectionPhoneNumberVerifier = params.s === 'verifier';
    });
  }
}
